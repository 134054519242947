import { useTranslation } from 'react-i18next';
import { useGetPublicationDomains } from '../../hooks/useGetPublicationDomains';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import _ from 'lodash';
import Constants from '../../../../config/Constants';
import { LinkButton } from '../../../../components/Buttons';
import React from 'react';

export function PublicationVersionFiles({ version }) {
    const { t } = useTranslation('publications');
    const publicationDomains = useGetPublicationDomains();
    const domain = publicationDomains.find((_domain) => _domain.id === version.domainId);

    const mediaTypes = ['html', 'pdf', 'printPdf', 'xml'];

    return (
        <tr className="expanded-row-details">
            <td colSpan={9}>
                <div className="px-3 pb-3">
                    <div className="dr-container px-3 pt-3 pb-2 bg-white">
                        <table className="table dr-table">
                            <thead>
                                <tr>
                                    <th>{t('publication.columnTitles.type')}</th>
                                    <th>{t('publication.columnTitles.url')}</th>
                                    <th>{t('publication.columnTitles.hash')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mediaTypes.map((mediaType) => (
                                    <VersionMedia
                                        version={version}
                                        property={mediaType}
                                        publicationDomain={domain}
                                        key={`version-${version.id}-type-${mediaType}`}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </td>
        </tr>
    );
}

function VersionMedia({ property, version, publicationDomain }) {
    const { t } = useTranslation('publications');

    if (property === 'html' && version.pdfOnly) {
        return null;
    }

    const linkUrl = url(property + (version.published ? 'Url' : 'ArchiveUrl'));

    if (linkUrl === '') {
        return null;
    }

    const result = property.replace(/([A-Z])/g, ' $1');
    const title = result.charAt(0).toUpperCase() + result.slice(1);

    const hash = _.get(version, `contentHashes.${property}`);

    return (
        <tr>
            <td>{title}</td>
            <td>
                <div className="flex-center">
                    <LinkButton
                        className="flex-center text-darkBlueAlt font-weight-normal text-left p-0 mr-2"
                        style={{ fontSize: 12 }}
                        disabled={version.status === Constants.publicationVersionStatus.processing_failed}
                        onClick={() => window.open(linkUrl, '_blank')}
                    >
                        {linkUrl}
                    </LinkButton>

                    {version.status !== Constants.publicationVersionStatus.processing_failed && (
                        <CopyToClipboard text={linkUrl} />
                    )}
                </div>
            </td>
            <td>
                {hash ? (
                    <div className="flex-center text-monospace">
                        <div className="mr-2">{hash.substring(0, 7)}</div>
                        <CopyToClipboard text={hash} />
                    </div>
                ) : (
                    '-'
                )}
            </td>
        </tr>
    );

    function url(propName, protocol = 'https') {
        const path = version[propName] ?? '';

        if (path === '') {
            return '';
        }

        if (path.startsWith('http')) {
            return path;
        }

        return `${protocol}://${publicationDomain.domain}/${path}`;
    }
}
