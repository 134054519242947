import { generatePath, useHistory, useParams } from 'react-router-dom';
import { EDIT_PATH } from '../../../../scenes/PublicationsV2';
import { useTranslation } from 'react-i18next';
import Constants from '../../../../config/Constants';
import { Dropdown, SplitButton } from 'react-bootstrap';
import { Files, Trash3 } from 'react-bootstrap-icons';
import { setModal } from '../../../../features/publications/publicationsSlice';
import { useDispatch } from 'react-redux';
import HelperFunctions from '../../../global/HelperFunctions';
import { useDeletePublicationsMutation } from '../../../../features/publications/publicationApi';
import { useAbility } from '../../../../ability/useAbility';

export function PublicationEditButton({ publication }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { folderId } = useParams();
    const { t } = useTranslation('publications');
    const [deletePublications] = useDeletePublicationsMutation();
    const ability = useAbility();

    return (
        <SplitButton
            id={`publication-${publication.id}-dropdown`}
            variant="primary"
            size="sm"
            title={t('publications.btn.edit')}
            className="flex-shrink-0"
            onClick={handleClick}
            onSelect={handleSelect}
            onToggle={(isOpen, event) => event.stopPropagation()}
        >
            <Dropdown.Item eventKey="duplicate" disabled={ability.cannot('create', 'Publication')}>
                <div className="flex-center">
                    <Files size={16} className="mr-2" />
                    {t('publication.dropdown.duplicate')}&hellip;
                </div>
            </Dropdown.Item>

            {!publication.guardEnabled && (
                <>
                    <Dropdown.Divider />

                    <Dropdown.Item eventKey="delete">
                        <div className="flex-center text-danger">
                            <Trash3 size={16} className="mr-2" />
                            {t('publication.dropdown.delete')}&hellip;
                        </div>
                    </Dropdown.Item>
                </>
            )}
        </SplitButton>
    );

    function handleSelect(eventKey) {
        switch (eventKey) {
            case 'duplicate':
                dispatch(
                    setModal({
                        key: 'duplicate',
                        value: {
                            publications: [publication['@id']],
                            folder: undefined,
                        },
                    }),
                );
                return;

            case 'delete':
                handleRemove();
                return;
        }
    }

    function handleRemove() {
        if (publication.status === Constants.publicationStatus.published) {
            HelperFunctions.alertModal(t('publication.dropdown.deleteModal.error')).then(() => {
                return false;
            });
        } else {
            HelperFunctions.confirmModal(
                `${t('publication.dropdown.deleteModal.confirmDeletion', { publication: publication.name })}`,
                'danger',
                false,
                t('publication.dropdown.deleteModal.btn.confirm'),
                t('btn.cancel'),
            )
                .then(() => {
                    deletePublications({
                        publications: [publication.id],
                    });
                })
                .catch(() => {});
        }
    }

    function handleClick(e) {
        e.stopPropagation();

        history.push(
            generatePath(EDIT_PATH, {
                publicationId: publication.id,
                action: 'properties',
                groupId: folderId ? folderId : undefined,
            }),
        );
    }
}
