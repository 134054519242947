import { useGetPublicationGroupsQuery } from '../../../features/publications/publicationApi';
import HelperFunctions from '../../global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetPublicationGroups(returnAsNested = true) {
    const activeOrganisation = useActiveOrganisation();

    const filterGroups = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (data, organisationId) => {
                if (data) {
                    return data.filter((_group) => _group.organisationId === organisationId);
                }

                return emptyArray;
            },
        );
    }, []);

    const { publicationGroups } = useGetPublicationGroupsQuery(undefined, {
        selectFromResult: (result) => ({
            publicationGroups: filterGroups(result, activeOrganisation),
        }),
    });

    if (returnAsNested) {
        return publicationGroups
            .filter((_group) => _group.parent === null)
            .map((_group) => ({
                ..._group,
                children: _group.children
                    .map((childUri) => publicationGroups.find((_item) => _item['@id'] === childUri))
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            }))
            .sort(HelperFunctions.dynamicSort('sortOrder'));
    }

    return publicationGroups;
}
