import LoadingSpinner from '../../../global/LoadingSpinner';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import cx from 'classnames';
import React, { useState } from 'react';
import { SelectAllCheckbox } from '../index/BulkActions';
import { RootPublications } from './RootPublications';
import GroupPublications from './Groups';
import _ from 'lodash';
import HelperFunctions from '../../../global/HelperFunctions';
import Constants, { Permissions } from '../../../../config/Constants';
import RestrictedContent, { useHasModuleEnabled } from '../../../global/RestrictedContent';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGetPublications } from '../../hooks/useGetPublications';
import { publicationApi, useEditPublicationsMutation } from '../../../../features/publications/publicationApi';
import { useGetPublicationGroup } from '../../hooks/useGetPublicationGroup';
import { useSelector } from 'react-redux';
import { useGetSelectedGroupId } from '../../hooks/useGetSelectedGroupId';
import { Skeleton } from '../../../../components/Skeleton';

export default function PublicationsWrapper({ allPublications, handleMove }) {
    const [isDragging, setDragging] = useState(false);
    const [editPublications] = useEditPublicationsMutation();

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragStart={() => setDragging(true)}>
            <div
                className={cx('data-table data-table-publications mt-3', {
                    'is-dragging': isDragging,
                })}
            >
                <PublicationsWrapperInner handleMove={handleMove} />
            </div>
        </DragDropContext>
    );

    function onDragEnd(result) {
        setDragging(false);
        const { destination, source } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        const sourceGroup = source.droppableId === 'main' ? null : source.droppableId;
        const destinationGroup = destination.droppableId === 'main' ? null : destination.droppableId;

        // Sort within group
        if (sourceGroup === destinationGroup) {
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );

            const [removed] = newPublications.splice(source.index, 1);
            newPublications.splice(destination.index, 0, removed);
            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update APi
            editPublications(changes);
        } else {
            // Remove from old group
            let oldPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === sourceGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            const [removed] = oldPublications.splice(source.index, 1);

            if (!removed) {
                return;
            }

            // Add to new group
            removed.publicationGroup = destinationGroup;
            let newPublications = _.cloneDeep(
                allPublications
                    .filter((_pub) => _pub.publicationGroup === destinationGroup)
                    .sort(HelperFunctions.dynamicSort('sortOrder')),
            );
            newPublications.splice(destination.index, 0, removed);

            newPublications = newPublications.map((_pub, index) => {
                return {
                    ..._pub,
                    sortOrder: index,
                };
            });

            const changes = newPublications.map((_pub) => {
                return {
                    id: _pub.id,
                    changes: {
                        publicationGroup: _pub.publicationGroup,
                        sortOrder: _pub.sortOrder,
                    },
                };
            });

            // Update APi
            editPublications(changes);
        }
    }
}

function PublicationsWrapperInner({ handleMove }) {
    const groupId = useGetSelectedGroupId();

    if (groupId === '0') {
        return <RootPublications handleMove={handleMove} />;
    }

    return (
        <>
            <div className="dr-container">
                <GroupTitle groupId={groupId} />
                <Publications />
            </div>
        </>
    );
}

function GroupTitle({ groupId }) {
    const folder = useGetPublicationGroup(groupId);

    return (
        <div
            className="data-table-group-header d-flex align-items-center"
            style={{
                fontSize: '14px',
                backgroundColor: '#f3f5f7',
                fontWeight: 500,
                padding: '0.55rem 0 0.55rem 1.2rem',
            }}
        >
            <div className="mr-2">{folder ? <>{folder.name}</> : <Skeleton />}</div>
        </div>
    );
}

function Publications() {
    const { documentId, search } = useSelector((state) => state.publications.filters);
    const groupId = useGetSelectedGroupId(true);
    const groupIdAsNumber = useGetSelectedGroupId();
    const publications = useGetPublications(groupId === '0' ? null : groupId, documentId, search);
    const { t } = useTranslation('publications');

    const { isFetching, isLoading, isUninitialized } = publicationApi.endpoints.getPublications.useQueryState();
    const showLoading = (isFetching || isLoading) && isUninitialized;

    if (showLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className="mb-4">
            <Droppable droppableId={groupId}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className={cx('data-table-group', 'data-table-group-level-1', {
                            'dragging-over': snapshot.isDraggingOver,
                        })}
                    >
                        <GroupHeader publications={publications} groupId={groupIdAsNumber} />

                        {publications.length > 0 && <GroupPublications publications={publications} />}

                        {isLoading && publications.length === 0 && (
                            <div className="p-3">
                                <LoadingSpinner inline size="sm" />
                            </div>
                        )}

                        {!isLoading && publications.length === 0 && (
                            <div className="p-3">
                                <div
                                    className="small text-secondary"
                                    style={{ lineHeight: '21px', marginLeft: '33px' }}
                                >
                                    <span style={{ marginLeft: '30px' }}>{t('publications.noPublications')}</span>
                                </div>
                            </div>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </div>
    );
}

export function GroupHeader({ publications = [], groupId }) {
    const rowIds = publications.map((_pub) => _pub.id);
    const { t } = useTranslation('publications');
    const hasGuardModuleEnabled = useHasModuleEnabled(Constants.modules.guard);

    const hasMulti = publications.some((publication) => publication.multiVersions);

    return (
        <div className="d-flex align-items-center py-2 pl-1 pr-2">
            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <div style={{ width: 50 }}>
                    <div style={{ paddingLeft: 24, marginTop: 8 }}>
                        <SelectAllCheckbox rowIds={rowIds} groupId={groupId} />
                    </div>
                </div>
            </RestrictedContent>

            <Container fluid className="small font-weight-bolder pt-2">
                <Row>
                    <div
                        className={cx({
                            'col-md-5': !hasMulti && !hasGuardModuleEnabled,
                            'col-md-4': (!hasMulti && hasGuardModuleEnabled) || (hasMulti && !hasGuardModuleEnabled),
                            'col-md-3': hasMulti && hasGuardModuleEnabled,
                        })}
                    >
                        <div className="d-flex align-items-center pl-2">
                            <div className="text-muted">{t('publications.columnTitles.name')}</div>
                        </div>
                    </div>

                    {hasGuardModuleEnabled && (
                        <div className="col-md-1 text-muted text-center">{t('publications.columnTitles.guard')}</div>
                    )}

                    <div className="col-md-2 text-muted">{t('publications.columnTitles.status')}</div>

                    {hasMulti && (
                        <div className="col-md-2 text-muted">{t('publications.columnTitles.publishedVersions')}</div>
                    )}

                    <div
                        className={cx('text-muted', {
                            'col-md-5': !hasMulti,
                            'col-md-4': hasMulti,
                        })}
                    >
                        {t('publications.columnTitles.document')}
                    </div>
                </Row>
            </Container>
        </div>
    );
}
