import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Accordion, AccordionContext, Badge, Col, Container, Row } from 'react-bootstrap';
import cx from 'classnames';
import {
    CaretRightFill,
    Display,
    FileEarmarkLock2,
    PersonLock,
    ShieldShaded,
    ShieldSlash,
} from 'react-bootstrap-icons';
import { publicationIsSelected, toggleRow } from 'features/publications/publicationsSlice';
import Constants, { Permissions } from '../../../../config/Constants';
import _ from 'lodash';
import RestrictedContent, { useHasModuleEnabled, useHasPermission } from '../../../global/RestrictedContent';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { generatePath, Link, useParams } from 'react-router-dom';
import { Checkbox } from '../../helpers/FieldHelper';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { PublicationStatusBadgeWithTooltip } from './PublicationStatusBadge';
import { useGetPublicationVersions } from 'pages/publications_v2/hooks/useGetPublicationVersions';
import { VIEW_PATH } from 'scenes/DocumentsV2';
import { PublicationVersions } from '../publication_row/PublicationVersions';
import { PublicationPreviews } from '../publication_row/PublicationPreviews';
import { PublicationEditButton } from './PublicationEditButton';
import { CopyToClipboard } from '../../../../components/CopyToClipboard';
import { useGetPublicationUrls } from '../../hooks/useGetPublicationUrls';

export default function Publication({ publication, eventKey, isExpanded = false, allDocuments = [] }) {
    const { t } = useTranslation('publications');
    const hasGuardModuleEnabled = useHasModuleEnabled(Constants.modules.guard);

    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
    });

    return (
        <div ref={ref}>
            <ScrollablePublication currentPublicationId={publication.id} inView={inView}>
                <PublicationRowWrapper publicationId={publication.id}>
                    <Container fluid>
                        <Accordion.Toggle as="div" className="row align-items-center" eventKey={eventKey}>
                            <Col
                                className={cx({
                                    'col-md-5': !publication.multiVersions && !hasGuardModuleEnabled,
                                    'col-md-4': !publication.multiVersions && hasGuardModuleEnabled,
                                    'col-md-3': publication.multiVersions && hasGuardModuleEnabled,
                                })}
                            >
                                <div className="d-flex cursor-pointer">
                                    <PublicationName publication={publication} inView={inView} />
                                    <CaretRightFill className="caret flex-shrink-0 ml-3" size={12} />
                                </div>
                            </Col>
                            {hasGuardModuleEnabled && (
                                <Col md={1} className="text-center">
                                    {publication.guardEnabled ? (
                                        <ShieldShaded
                                            className="text-primary"
                                            size={18}
                                            data-uk-tooltip={t('publications.tooltip.guardEnabled')}
                                        />
                                    ) : (
                                        <ShieldSlash
                                            className="text-muted"
                                            size={18}
                                            data-uk-tooltip={t('publications.tooltip.guardDisabled')}
                                        />
                                    )}
                                </Col>
                            )}
                            <Col md={2}>
                                <PublicationStatus publication={publication} />
                            </Col>
                            {publication.multiVersions && (
                                <Col md={2}>
                                    {publication.status === Constants.publicationStatus.published && (
                                        <PublicationStatusMulti publicationId={publication.id} inView={inView} />
                                    )}
                                </Col>
                            )}
                            <Col
                                className={cx({
                                    'col-md-5': !publication.multiVersions,
                                    'col-md-4': publication.multiVersions,
                                })}
                            >
                                <div className="d-flex flex-row-reverse align-items-center justify-content-between">
                                    <RestrictedContent permission={Permissions.Publication['Write.All']}>
                                        <PublicationEditButton publication={publication} />
                                    </RestrictedContent>

                                    <PublicationDocuments
                                        documentIds={publication.documentIds}
                                        allDocuments={allDocuments}
                                    />
                                </div>
                            </Col>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={eventKey}>
                            <Row>
                                <div className="col pt-2">
                                    <div className="expanded-row-details">
                                        <div className="px-1">
                                            <div className="border-top mt-2"></div>
                                            {isExpanded && (
                                                <div className="pt-4">
                                                    <PublicationPreviews publication={publication} />
                                                    <PublicationVersions publication={publication} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </Accordion.Collapse>
                    </Container>
                </PublicationRowWrapper>
            </ScrollablePublication>
        </div>
    );
}

function ScrollablePublication({ currentPublicationId, inView, children }) {
    const { publicationId } = useParams();
    const [didScroll, setDidScroll] = useState(false);
    const innerRef = useRef();

    useEffect(() => {
        const pid = publicationId ? parseInt(publicationId) : undefined;

        if (currentPublicationId !== pid) {
            return;
        }

        if (!inView && !didScroll) {
            if (innerRef.current) {
                innerRef.current.scrollIntoView({ behavior: 'smooth' });
                setDidScroll(true);
            }
        } else {
            setDidScroll(true);
        }
    }, [currentPublicationId, publicationId, inView, innerRef.current]);

    return <div ref={innerRef}>{children}</div>;
}

function PublicationRowWrapper({ publicationId, children }) {
    const rowIsSelected = useSelector(publicationIsSelected(publicationId));
    const hasReadPermission = useHasPermission(Permissions.Publication['Read.All']);
    const currentEventKey = useContext(AccordionContext);
    const expanded = currentEventKey === `publication-${publicationId}`;

    return (
        <div
            className={cx('d-flex align-items-center py-2 pl-1 pr-2 mr-2', {
                'data-table-item-expanded': expanded,
                'data-table-item-selected': rowIsSelected,
                'data-table-item-disable': hasReadPermission === false,
            })}
        >
            <PublicationSelect publicationId={publicationId} />
            <div
                className={cx('d-flex align-items-center flex-grow-1 border mr-2 ml-2', {
                    'bg-light': expanded,
                    'bg-white cursor-pointer uk-background-theme-light-hover': !expanded,
                })}
                style={{
                    paddingTop: '0.85rem',
                    paddingBottom: '0.85rem',
                }}
            >
                {children}
            </div>
        </div>
    );
}

function PublicationSelect({ publicationId }) {
    const dispatch = useDispatch();
    const rowIsSelected = useSelector(publicationIsSelected(publicationId));

    const handleChange = () => {
        dispatch(toggleRow(publicationId));
    };

    return (
        <div className="align-self-start d-flex flex-shrink-0" style={{ marginTop: 18 }}>
            <RestrictedContent permission={Permissions.Publication['Write.All']}>
                <RxDragHandleDots2 size={22} className="icon-grip flex-shrink-0 mr-1 ml-0 text-muted" />

                <Checkbox
                    id={`publication-check-${publicationId}`}
                    name={`publication-check-${publicationId}`}
                    checked={rowIsSelected}
                    onChange={handleChange}
                />
            </RestrictedContent>
        </div>
    );
}

function PublicationName({ publication, inView }) {
    const { pdf = false, pdfProtection = false } = publication;
    const { t } = useTranslation('publications');

    const isProtected = pdf && pdfProtection;
    const enableGroupProperties = publication.accessControlEnabled ?? false;

    return (
        <div>
            <div
                style={{
                    lineHeight: '18px',
                }}
                className="d-flex align-items-center font-weight-bolder"
            >
                <div className="line-clamp">{publication.name}</div>

                {isProtected && (
                    <FileEarmarkLock2
                        size={15}
                        className="flex-shrink-0 ml-2"
                        data-uk-tooltip={t('publications.tooltip.pdfSecurity')}
                    />
                )}

                {enableGroupProperties && (
                    <PersonLock
                        size={15}
                        className="flex-shrink-0 ml-2"
                        data-uk-tooltip="Toegangsbeperking ingeschakeld"
                    />
                )}

                {publication.includeInKiosk && (
                    <Display
                        size={15}
                        className="flex-shrink-0 ml-2"
                        data-uk-tooltip={t('publications.tooltip.includeInKiosk')}
                    />
                )}

                {publication.new && (
                    <Badge variant="warning" className="ml-2 small">
                        {t('publications.badges.new')}
                    </Badge>
                )}
            </div>

            <PublicationInfo publication={publication} inView={inView} />
        </div>
    );
}

function PublicationStatus({ publication }) {
    const { status } = publication;
    const { t } = useTranslation('publications');

    if (status === Constants.publicationStatus.published) {
        return <PublicationStatusPublished publication={publication} />;
    }

    const statusLabels = {
        [Constants.publicationStatus.publishing_failed]: t('publications.badges.failed'),
        [Constants.publicationStatus.draft]: t('publications.badges.notPublished'),
        [Constants.publicationStatus.publishing]: `${t('publications.badges.publishing')}...`,
    };

    return (
        <div className="font-weight-bolder font-size-base">
            <Badge
                variant={cx({
                    secondary: status === Constants.publicationStatus.draft,
                    danger: status === Constants.publicationStatus.publishing_failed,
                    warning: status === Constants.publicationStatus.publishing,
                })}
            >
                {statusLabels[status]}
            </Badge>
        </div>
    );
}

function PublicationInfo({ publication, inView }) {
    const hasPrefix = !_.isEmpty(publication.prefix);
    const showPrefix = hasPrefix && publication.multiVersions;

    return (
        <div className="flex-center11 text-muted small" style={{ paddingTop: 3, wordBreak: 'break-all' }}>
            {showPrefix && <>{publication.prefix}</>}

            {inView && <PublicationUrl publication={publication} hasPrefix={showPrefix} />}
        </div>
    );
}

function PublicationUrl({ publication, hasPrefix }) {
    const { htmlUrl, pdfUrl } = useGetPublicationUrls(publication);

    if (_.isEmpty(htmlUrl) && _.isEmpty(pdfUrl)) {
        return null;
    }

    const isPublished = publication.status === Constants.publicationStatus.published;
    const url = new URL(_.isEmpty(htmlUrl) ? pdfUrl : htmlUrl);

    return (
        <>
            {hasPrefix && <>&nbsp;&bull;&nbsp;</>}

            {isPublished ? (
                <>
                    <a onClick={(e) => e.stopPropagation()} href={url.href} target="_blank" className="text-muted mr-2">
                        {url.pathname}
                    </a>
                    <CopyToClipboard text={url.href} />
                </>
            ) : (
                <span>{url.pathname}</span>
            )}
        </>
    );
}

function PublicationStatusMulti({ inView = false, publicationId }) {
    const { t } = useTranslation('publications');

    const skipLoadingVersions = !inView;
    const { publicationVersions } = useGetPublicationVersions(publicationId, skipLoadingVersions);

    if (publicationVersions.length === 0) {
        return null;
    }

    const groupedVersions = _.groupBy(publicationVersions, 'prefix');

    return (
        <>
            {Object.keys(groupedVersions).map((prefix) => {
                const primaryVersion = groupedVersions[prefix].find((version) => {
                    return version.primaryVersion && version.status === Constants.publicationVersionStatus.published;
                });

                if (!primaryVersion) {
                    return null;
                }

                return (
                    <a
                        href={primaryVersion.htmlUrl}
                        className="badge badge-primary mr-1"
                        target="_blank"
                        data-uk-tooltip={`${t('publication.tooltip.newWindow', { name: 'Html' })}`}
                        key={`publication-${publicationId}-prefix-${prefix}`}
                    >
                        {prefix}
                    </a>
                );
            })}
        </>
    );
}

function PublicationStatusPublished({ publication }) {
    const { t } = useTranslation('publications');
    const hasCreatedAt = _.has(publication, 'publishedAt');
    const hasCreatorName = _.has(publication, 'publishedBy');
    const showTooltip = hasCreatedAt && hasCreatorName;

    const TheBadge = (
        <Badge
            variant="success"
            style={{
                cursor: showTooltip ? 'help' : undefined,
            }}
        >
            {t('publications.badges.published')}
        </Badge>
    );

    if (showTooltip) {
        return (
            <PublicationStatusBadgeWithTooltip
                publishedBy={publication.publishedBy}
                publishedAt={publication.publishedAt}
            >
                {TheBadge}
            </PublicationStatusBadgeWithTooltip>
        );
    }

    return TheBadge;
}

function PublicationDocuments({ documentIds, allDocuments = [] }) {
    const documents = allDocuments
        .filter((_document) => documentIds.includes(_document.id))
        .map((_document) => _document.name);

    return (
        <div
            className="line-clamp mr-3 pl-2"
            style={{
                lineHeight: '16px',
            }}
        >
            {documents.length === 1 ? (
                <>
                    <Link
                        to={generatePath(VIEW_PATH, {
                            documentId: documentIds[0],
                        })}
                        className="font-weight-bolder text-dark-blue-alt small"
                        target="_blank"
                    >
                        {documents[0]}
                    </Link>
                </>
            ) : (
                <>{documents.join(', ')}</>
            )}
        </div>
    );
}
