import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Form as FForm, Formik } from 'formik';
import { FormModal, ModalFooter } from '../../global/FormModal';
import _ from 'lodash';
import { FieldSelect, Switch } from '../helpers/FieldHelper';
import HelperFunctions from '../../global/HelperFunctions';
import { useEditPublicationsMutation } from '../../../features/publications/publicationApi';
import { useGetPublicationDomains } from 'pages/publications_v2/hooks/useGetPublicationDomains';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../features/publications/publicationsSlice';

export function KioskBulkEditModal() {
    const dispatch = useDispatch();
    const publicationDomains = useGetPublicationDomains();
    const [editPublications] = useEditPublicationsMutation();
    const { kioskBulkEdit } = useSelector((state) => state.publications.modals);

    const payload = kioskBulkEdit;
    const showModal = !!kioskBulkEdit;

    if (!showModal) {
        return null;
    }

    const { publications = [] } = payload;

    const domainIds = _.uniq(publications.map((publication) => publication.domainId));
    const domainId = domainIds[0];
    const domain = publicationDomains.find((publicationDomain) => publicationDomain.id === domainId);

    const sortedCategories = [...domain.kioskCategories].sort(HelperFunctions.sortByString('categoryName'));
    const categoryOptions = HelperFunctions.prepareDropdownData(sortedCategories, 'categoryName');

    const categoryIds = _.uniq(publications.map((publication) => publication.kioskCategoryId));

    return (
        <FormModal show={showModal} onHide={handleClose} title="Koppelen aan kiosk">
            <Formik
                initialValues={{
                    includeInKiosk: true,
                    categoryId: categoryIds.length === 1 ? categoryIds[0] : undefined,
                }}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, isValid, dirty, values }) => (
                    <FForm autoComplete="off">
                        <Modal.Body>
                            <Switch name="includeInKiosk" label="Toon in kiosk" />

                            {values.includeInKiosk && (
                                <Form.Group>
                                    <FieldSelect
                                        label="Selecteer categorie"
                                        name="categoryId"
                                        options={categoryOptions}
                                    />
                                </Form.Group>
                            )}
                        </Modal.Body>

                        <ModalFooter isValid={isValid} dirty={dirty} isSubmitting={isSubmitting} onHide={handleClose} />
                    </FForm>
                )}
            </Formik>
        </FormModal>
    );

    function handleSubmit(values) {
        const changes = publications.map((_pub) => {
            return {
                id: _pub.id,
                changes: {
                    includeInKiosk: values.includeInKiosk,
                    kioskCategoryId: values.includeInKiosk ? values.categoryId : null,
                },
            };
        });

        editPublications(changes).then(() => {
            handleClose();
        });
    }

    function handleClose() {
        dispatch(closeModal('kioskBulkEdit'));
    }
}
