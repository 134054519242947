import { useGetPublicationQuery } from '../../../features/publications/publicationApi';

export function useGetPublication(id) {
    const { publication } = useGetPublicationQuery(id, {
        selectFromResult: ({ data }) => ({
            publication: data,
        }),
        skip: id === 0,
    });

    return publication;
}
