import { Accordion } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import React, { useContext } from 'react';
import Publication from './Publication';
import cx from 'classnames';
import { useHasPermission } from '../../../global/RestrictedContent';
import { Permissions } from '../../../../config/Constants';
import { useParams } from 'react-router-dom';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useGetDocuments } from '../../../documents_v2/hooks/useGetDocuments';

export default function GroupPublications({ publications = [] }) {
    const allDocuments = useGetDocuments();
    const { publicationId } = useParams();
    const hasReadWritePermission = useHasPermission(Permissions.Publication['Write.All']);

    return (
        <Accordion defaultActiveKey={publicationId ? `publication-${publicationId}` : undefined}>
            <div className="data-table-items">
                {publications.map((publication, index) => (
                    <DraggablePublication
                        publication={publication}
                        index={index}
                        hasReadWritePermission={hasReadWritePermission}
                        eventKey={`publication-${publication.id}`}
                        allDocuments={allDocuments}
                        key={`publication-${publication.id}`}
                    />
                ))}
            </div>
        </Accordion>
    );
}

function DraggablePublication({ publication, index, hasReadWritePermission, eventKey, allDocuments }) {
    const currentEventKey = useContext(AccordionContext);
    const isExpanded = currentEventKey === eventKey;

    return (
        <Draggable
            draggableId={publication['@id']}
            index={index}
            isDragDisabled={hasReadWritePermission === false || isExpanded}
        >
            {(provided, snapshot) => {
                return (
                    <div
                        id={`publication-item-${publication.id}`}
                        className={cx('data-table-item-wrapper', {
                            'is-dragging': snapshot.isDragging,
                        })}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        <Publication
                            publication={publication}
                            eventKey={eventKey}
                            isExpanded={isExpanded}
                            allDocuments={allDocuments}
                        />
                    </div>
                );
            }}
        </Draggable>
    );
}
