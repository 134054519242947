import { useGetPublicationsQuery } from 'features/publications/publicationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';
import HelperFunctions from 'pages/global/HelperFunctions';
import { useActiveOrganisation } from '../../../hooks/useActiveOrganisation';
import _ from 'lodash';

export function useGetPublications(groupId, documentId, search) {
    const activeOrganisation = useActiveOrganisation();

    const selectPublications = useMemo(() => {
        const emptyArray = [];

        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, organisationId) => organisationId,
            (res, organisationId, groupId) => groupId,
            (res, organisationId, groupId, documentId) => documentId,
            (res, organisationId, groupId, documentId, search) => search,
            (data, organisationId, groupId, documentId, search) => {
                let publications = data
                    ? data.filter((publication) => publication.organisationId === organisationId)
                    : emptyArray;

                // Undefined means we want all publications, null means only top-level
                if (groupId !== undefined) {
                    publications = publications.filter((_publication) => _publication.publicationGroup === groupId);
                }

                if (_.isInteger(documentId)) {
                    publications = publications.filter((_publication) => _publication.documentIds.includes(documentId));
                }

                if (!_.isEmpty(search)) {
                    publications = publications.filter((_publication) => {
                        const searchValueClean = search.trim().toLowerCase();

                        if (_publication.prefix && _publication.prefix.toLowerCase().includes(searchValueClean)) {
                            return true;
                        }

                        return _publication.name.toLowerCase().includes(searchValueClean);
                    });
                }

                return publications.sort(HelperFunctions.dynamicSort('sortOrder'));
            },
        );
    }, []);

    const { publications } = useGetPublicationsQuery(undefined, {
        selectFromResult: (result) => ({
            publications: selectPublications(result, activeOrganisation, groupId, documentId, search),
        }),
    });

    return publications;
}
