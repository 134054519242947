import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { normalize } from 'normalizr';
import { publicationGroupEntity } from '../../schemas';
import CloneApi from '../../api/CloneApi';

const PublicationAdapter = createEntityAdapter();
const PublicationPreviewTokenAdapter = createEntityAdapter({
    selectId: (entity) => entity['@id'],
});
const PublicationGroupAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
});
const OpListExportTokenAdapter = createEntityAdapter();

export const batchDuplicatePublications = createAsyncThunk(
    'publications/batchDuplicatePublications',
    async ({ formData }) => {
        const { data } = await CloneApi.createBatchCloneTask('publications', formData);

        return data;
    },
);

export const duplicatePublicationGroup = createAsyncThunk(
    'publications/duplicatePublicationGroup',
    async ({ id, formData }) => {
        const results = await CloneApi.createBatchCloneTask('publication_groups', formData);
        const normalized = normalize(results.data, publicationGroupEntity);

        return normalized.entities;
    },
);

const publicationsSlice = createSlice({
    name: 'publications',
    initialState: {
        publications: PublicationAdapter.getInitialState(),
        publicationGroups: PublicationGroupAdapter.getInitialState(),
        publicationVersions: PublicationGroupAdapter.getInitialState(),
        publicationPreviewTokens: PublicationPreviewTokenAdapter.getInitialState(),
        opListExportTokens: OpListExportTokenAdapter.getInitialState(),
        filters: {
            documentId: '',
            search: '',
        },
        expandedPublicationId: '',
        selectedRows: [],
        modals: {
            group: false,
            publish: false,
            exportChanges: false,
            duplicate: false,
            movePublication: false,
            kioskBulkEdit: false,
        },
    },
    reducers: {
        setFilter: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.filters[key] = value;
            },
        },
        setModal: {
            reducer(state, action) {
                const { key, value } = action.payload;
                state.modals[key] = value;
            },
        },
        closeModal: {
            reducer(state, action) {
                state.modals[action.payload] = false;
            },
        },
        setSelectedRows: {
            reducer(state, action) {
                state.selectedRows = action.payload;
            },
        },
        toggleRow: {
            reducer(state, action) {
                const id = action.payload;
                const { selectedRows } = state;

                state.selectedRows = selectedRows.includes(id)
                    ? selectedRows.filter((i) => i !== id)
                    : [...selectedRows, id];
            },
        },
        editPublications: {
            reducer(state, { payload }) {
                PublicationAdapter.updateMany(state.publications, payload);
            },
        },
    },
    extraReducers: {
        ['security/resetState']: (state) => {
            Object.assign(state, publicationsSlice.getInitialState());
        },
    },
});
export const { setFilter, editPublications, toggleRow, setSelectedRows, closeModal, setModal } =
    publicationsSlice.actions;

export const publicationIsSelected = (id) =>
    createSelector([(state) => state.publications.selectedRows], (selectedRows) => {
        return selectedRows.includes(id);
    });

export default publicationsSlice.reducer;
