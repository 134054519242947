import { useGetPublicationVersionsQuery } from 'features/publications/publicationApi';
import Constants from '../../../config/Constants';
import { useEffect, useMemo, useState } from 'react';
import { createSelector } from '@reduxjs/toolkit';

const emptyArray = [];

export function useGetPublicationVersions(publicationId, skip = false) {
    const [autoRefresh, setAutoRefresh] = useState(false);

    const versionsArePending = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (data) => data,
            (data) => {
                return data.some((_version) => {
                    return pendingStatusCodes.includes(_version.status);
                });
            },
        );
    }, []);

    const queryResults = useGetPublicationVersionsQuery(publicationId, {
        selectFromResult: ({ currentData, isFetching, isUninitialized }) => {
            const publicationVersions = currentData ? [...currentData].reverse() : emptyArray;

            return {
                publicationVersions,
                hasPendingVersions: versionsArePending(publicationVersions),
                isLoading: isUninitialized || (isFetching && publicationVersions.length === 0),
            };
        },
        skip: publicationId === undefined || skip === true,
        pollingInterval: autoRefresh ? 5000 : 0,
    });

    const { hasPendingVersions } = queryResults;

    useEffect(() => {
        setAutoRefresh(hasPendingVersions);
    }, [hasPendingVersions]);

    return queryResults;
}

export const pendingStatusCodes = [
    Constants.publicationVersionStatus.processing_document,
    Constants.publicationVersionStatus.in_queue,
    Constants.publicationVersionStatus.scheduledForPublishing,
    Constants.publicationVersionStatus.scheduledForUnpublishing,
    Constants.publicationVersionStatus.readyToPublish,
];
