import { Badge, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import cx from 'classnames';
import Constants, { Permissions } from '../../../../config/Constants';
import {
    ArrowUpShort,
    CaretRightFill,
    CheckCircleFill,
    CloudArrowDownFill,
    CloudArrowUpFill,
    GearWideConnected,
    Pencil,
    Trash3,
} from 'react-bootstrap-icons';
import RestrictedContent from '../../../global/RestrictedContent';
import HelperFunctions from '../../../global/HelperFunctions';
import { useTranslation } from 'react-i18next';
import { PublicationStatusBadgeWithTooltip } from '../index2/PublicationStatusBadge';
import _ from 'lodash';
import {
    useDeletePublicationVersionMutation,
    usePublishPublicationMutation,
    useUpdateTokenMutation,
} from 'features/publications/publicationApi';
import { IconButton } from 'components/Buttons';
import { useGetUserFullName } from 'hooks/useGetUserFullName';
import { pendingStatusCodes, useGetPublicationVersions } from 'pages/publications_v2/hooks/useGetPublicationVersions';
import { PublishActions } from './PublishActions';
import { TableRowSkeleton } from '../../../../components/Skeleton';
import { PublicationVersionFiles } from './PublicationVersionFile';

export function PublicationVersions({ publication }) {
    const { publicationVersions, hasPendingVersions, isLoading } = useGetPublicationVersions(publication.id);
    const { t } = useTranslation('publications');

    return (
        <div>
            <div className="font-weight-bolder ml-1 mb-2">{t('publication.titles.publications')}</div>

            <div className="dr-container px-3 pt-3 pb-2">
                <PublishActions publication={publication} publicationVersions={publicationVersions} />

                <table className="table dr-table">
                    <thead>
                        <tr>
                            <th className="pr-0" />
                            <th className="pl-1">
                                <div className="d-flex flex-nowrap">
                                    #&nbsp;
                                    <ArrowUpShort />
                                </div>
                            </th>
                            <th>
                                {t('publication.columnTitles.reference')}
                                {publication.multiVersions && <>&nbsp;- {t('publication.columnTitles.version')}</>}
                            </th>
                            <th>{t('publication.columnTitles.status')}</th>
                            <th>{t('publication.columnTitles.user')}</th>
                            <th>{t('publication.columnTitles.start')}</th>
                            <th>{t('publication.columnTitles.end')}</th>
                            <th>{t('publication.columnTitles.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <TableRowSkeleton rowCount={1} cellCount={8} />
                        ) : (
                            <>
                                {publicationVersions.length > 0 ? (
                                    <>
                                        {publicationVersions.map((version, index) => (
                                            <PublicationVersion
                                                publication={publication}
                                                version={version}
                                                index={publicationVersions.length - index}
                                                hasPendingVersions={hasPendingVersions}
                                                key={`version-${version.id}`}
                                            />
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td colSpan={8} className="text-muted">
                                                Er zijn nog geen versies gepubliceerd
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function PublicationVersion({ version, publication, index, hasPendingVersions = false }) {
    const [updateToken] = useUpdateTokenMutation();
    const [publishPublication] = usePublishPublicationMutation();
    const [deletePublicationVersion] = useDeletePublicationVersionMutation();
    const [showVersionFiles, setShowVersionFiles] = useState(false);

    const initialStart = DateTime.fromISO(version.publicationStart).toFormat("yyyy-MM-dd'T'HH:mm");
    const [tempStart, setTempStart] = useState(initialStart);

    const tempEndTest = version.publicationEnd
        ? DateTime.fromISO(version.publicationEnd).toFormat("yyyy-MM-dd'T'HH:mm")
        : '';
    const [tempEnd, setTempEnd] = useState(tempEndTest);

    const { t } = useTranslation('publications');
    const creatorName = useGetUserFullName(version.createdBy, false);

    const publicationStart = DateTime.fromISO(version.publicationStart ?? version.publishedAt);
    const formattedPublicationStart = publicationStart.isValid ? publicationStart.toFormat('dd/MM/yyyy HH:mm') : '-';

    const publicationEnd = version.publicationEnd ? DateTime.fromISO(version.publicationEnd) : undefined;
    const formattedPublicationEnd = publicationEnd ? publicationEnd.toFormat('dd/MM/yyyy HH:mm') : '-';

    const isArchived = version.status === Constants.publicationVersionStatus.published && !version.published;
    const versionIsPending = pendingStatusCodes.includes(version.status);

    const publicationId = publication?.id;
    const publicationVersionId = version?.id;

    const minDate = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm");

    return (
        <>
            <tr
                className={cx('cursor-pointer', { 'is-expanded': showVersionFiles })}
                onClick={() => setShowVersionFiles(!showVersionFiles)}
            >
                <td className="pr-0">
                    <CaretRightFill
                        className={cx('ml-2-body', {
                            'rotate-90': showVersionFiles,
                        })}
                    />
                </td>
                <td className={cx('pl-1', { 'font-weight-bold': showVersionFiles })}>#{index}</td>
                <td className={cx({ 'font-weight-bold': showVersionFiles })}>
                    {_.isEmpty(version.prefix) ? '-' : version.prefix}
                    {publication.multiVersions && <> - {version.counter}</>}
                </td>
                <td>
                    {version.published && (
                        <PublicationStatusBadgeWithTooltip
                            publishedBy={version.publishedBy}
                            publishedAt={version.publishedAt}
                        >
                            <Badge
                                variant="success"
                                className="m-0"
                                style={{
                                    cursor: 'help',
                                }}
                            >
                                {t('publication.columnData.badges.published')}
                            </Badge>
                        </PublicationStatusBadgeWithTooltip>
                    )}
                    {isArchived && (
                        <Badge variant="light" className="m-0">
                            {t('publication.columnData.badges.archived')}
                        </Badge>
                    )}
                    {version.status === Constants.publicationVersionStatus.processing_document && (
                        <Badge variant="warning" className="m-0">
                            {t('publication.columnData.badges.preparing')}
                        </Badge>
                    )}
                    {version.status === Constants.publicationVersionStatus.readyToPublish && (
                        <Badge variant="warning" className="m-0">
                            {t('publication.columnData.badges.publish')}
                        </Badge>
                    )}
                    {version.status === Constants.publicationVersionStatus.scheduledForPublishing && (
                        <Badge variant="secondary" className="m-0">
                            {t('publication.columnData.badges.plannedPublication')}
                        </Badge>
                    )}
                    {version.status === Constants.publicationVersionStatus.in_queue && (
                        <Badge variant="warning" className="m-0">
                            {t('publication.columnData.badges.inQueue')}
                        </Badge>
                    )}
                    {version.status === Constants.publicationVersionStatus.processing_failed && (
                        <Badge variant="danger" className="m-0">
                            {t('publication.columnData.badges.failed')}
                        </Badge>
                    )}
                </td>
                <td>{creatorName}</td>
                <td>
                    {version.status === Constants.publicationVersionStatus.scheduledForPublishing ? (
                        <div className="d-flex align-items-center">
                            <div className="mr-1">{formattedPublicationStart}</div>
                            <OverlayTrigger
                                trigger="click"
                                rootClose={true}
                                placement="bottom"
                                overlay={popoverStart()}
                            >
                                <Pencil className="flex-shrink-0 cursor-pointer" />
                            </OverlayTrigger>
                        </div>
                    ) : (
                        formattedPublicationStart
                    )}
                </td>
                <td>
                    {version.published ||
                    version.status === Constants.publicationVersionStatus.scheduledForPublishing ? (
                        <div className="d-flex align-items-center">
                            <div className="mr-1">{formattedPublicationEnd}</div>
                            <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popoverEnd()}>
                                <Pencil className="flex-shrink-0 cursor-pointer" />
                            </OverlayTrigger>
                        </div>
                    ) : (
                        <>{formattedPublicationEnd}</>
                    )}
                </td>

                <td>{renderPublicationVersionActions()}</td>
            </tr>

            {showVersionFiles && <PublicationVersionFiles version={version} />}
        </>
    );

    function renderPublicationVersionActions() {
        if (isIpidArchivePdfUrl(version)) {
            return '-';
        }

        const allowedStatusCodes = [
            Constants.publicationVersionStatus.published,
            Constants.publicationVersionStatus.processing_failed,
            Constants.publicationVersionStatus.scheduledForPublishing,
        ];

        const showVersionPublishAction = version.published && !hasPendingVersions;
        const showVersionActions =
            !hasPendingVersions && !showVersionPublishAction && allowedStatusCodes.includes(version.status);

        return (
            <>
                {versionIsPending ? (
                    <GearWideConnected size={18} className="icon-spin" />
                ) : (
                    <>
                        {publication.guardEnabled ? (
                            <>-</>
                        ) : (
                            <RestrictedContent permission={Permissions.Publication['Publish.All']}>
                                {showVersionPublishAction && (
                                    <IconButton
                                        icon={<CloudArrowDownFill size={18} className="text-danger" />}
                                        tooltip={`${t('publication.columnData.actions.dePublish')}...`}
                                        onClick={handleDePublish}
                                    />
                                )}

                                {showVersionActions && (
                                    <>
                                        <IconButton
                                            icon={<CloudArrowUpFill size={18} className="text-success" />}
                                            className="mr-1"
                                            tooltip={`${t('publication.columnData.actions.publishVersion')}...`}
                                            onClick={handlePublishVersion}
                                        />
                                        <IconButton
                                            icon={<Trash3 size={16} className="text-danger" />}
                                            tooltip={`${t('publication.columnData.actions.remove')}...`}
                                            onClick={handleRemoveVersion}
                                        />
                                    </>
                                )}
                            </RestrictedContent>
                        )}
                    </>
                )}
            </>
        );
    }

    function popoverStart() {
        return (
            <Popover id={`row-${version.id}-start`}>
                <Popover.Content>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="datetime-local"
                            name="publicationStart"
                            min={minDate}
                            value={tempStart}
                            onChange={(e) => setTempStart(e.target.value)}
                        />
                        <IconButton
                            className="flex-shrink-0 ml-2"
                            onClick={handleUpdateStart}
                            tooltip="Opslaan"
                            icon={<CheckCircleFill className="text-success" size={20} />}
                        />
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    function popoverEnd() {
        return (
            <Popover id={`row-${version.id}-end`}>
                <Popover.Content>
                    <div className="d-flex align-items-center">
                        <Form.Control
                            type="datetime-local"
                            name="publicationEnd"
                            min={minDate}
                            value={tempEnd}
                            onChange={(e) => setTempEnd(e.target.value)}
                        />
                        <IconButton
                            className="flex-shrink-0 ml-2"
                            onClick={handleUpdateEnd}
                            tooltip="Opslaan"
                            icon={<CheckCircleFill className="text-success" size={20} />}
                        />
                    </div>
                </Popover.Content>
            </Popover>
        );
    }

    function handleUpdateStart() {
        if (tempStart !== '') {
            const payload = {
                publicationId,
                publicationVersionId,
                action: 'publish',
                value: tempStart,
            };

            updateToken(payload);
        }
    }

    function handleUpdateEnd() {
        if (tempEnd !== '') {
            const payload = {
                publicationId,
                publicationVersionId,
                action: 'unpublish',
                value: tempEnd,
            };

            updateToken(payload);
        }
    }

    function handleDePublish() {
        HelperFunctions.confirmModal(
            `${t('publication.columnTitles.depublishModal.confirm', { name: publication.name })}`,
            'danger',
            false,
            t('publication.columnTitles.depublishModal.btn.confirm'),
            t('btn.cancel'),
        )
            .then(() => {
                publishPublication({
                    publication: publication['@id'],
                    publicationVersion: version ? version['@id'] : undefined,
                    action: Constants.publicationToken.action_unpublish,
                });
            })
            .catch(() => {});
    }

    function handlePublishVersion() {
        let versionNumber = `${index}`;

        if (publication.multiVersions) {
            versionNumber = `${version.prefix} - ${version.counter}`;
        }

        HelperFunctions.confirmModal(
            t('publications:publication.columnData.actions.publishVersionConfirm', { number: versionNumber }),
            undefined,
            false,
            t('publications:publication.btn.yesPublish'),
            t('global:btn.cancel'),
        )
            .then(() => {
                publishPublication({
                    publication: publication['@id'],
                    publicationVersion: version['@id'],
                    action: Constants.publicationToken.action_publish,
                });
            })
            .catch(() => {});
    }

    function handleRemoveVersion() {
        let versionNumber = `${index}`;

        if (publication.multiVersions) {
            versionNumber = `${version.prefix} - ${version.counter}`;
        }

        HelperFunctions.confirmModal(
            t('publications:publication.columnData.actions.removeVersionConfirm', { number: versionNumber }),
            'danger',
            false,
            t('global:confirm.yesDelete'),
            t('global:btn.cancel'),
        )
            .then(() => {
                deletePublicationVersion({ uri: version['@id'], publicationUri: publication['@id'] });
            })
            .catch(() => {});
    }
}

function isIpidArchivePdfUrl(version) {
    const regex =
        /^(https:\/\/(www\.)?verzekeringskaarten(-test)?\.nl)\/assets\/downloads\/.*(\d{4}_\d{2}_\d{2}_).*(\.pdf)/gm;

    return version.pdfOnly && _.isString(version.pdfUrl) && version.pdfUrl.match(regex);
}
