import { useGetTranslationsQuery } from 'features/translations/translationApi';
import { useMemo } from 'react';
import { createSelector } from '@reduxjs/toolkit';

/**
 * @param {object} [translationKey]
 * @param {string} languageIso
 */
export function useGetTranslation(translationKey, languageIso) {
    const filterTranslations = useMemo(() => {
        // Return a unique selector instance for this page so that
        // the filtered results are correctly memoized
        return createSelector(
            (res) => res.data,
            (res, languageIso) => languageIso,
            (data, languageIso) => {
                if (data) {
                    return data.find((translation) => translation.languageIso === languageIso);
                }

                return undefined;
            },
        );
    }, []);

    const { translation } = useGetTranslationsQuery(translationKey ? translationKey['@id'] : undefined, {
        selectFromResult: (result) => {
            return {
                translation: filterTranslations(result, languageIso),
            };
        },
        skip: translationKey === undefined,
    });

    return translation;
}
