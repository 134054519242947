import { useGetTranslationLanguageExportFilterQuery } from 'features/translations/translationApi';
import { JsonParam, NumberParam, useQueryParam } from 'use-query-params';
import HelperFunctions from 'pages/global/HelperFunctions';

export function useGetTranslationLanguageExportFilter(id) {
    const [page = 1] = useQueryParam('page', NumberParam);
    const [filterOptions] = useQueryParam('filters', JsonParam);

    const params = {
        page,
        ...defaultFilterOptions,
        ...filterOptions,
    };

    return useGetTranslationLanguageExportFilterQuery(
        { id, params },
        {
            selectFromResult: (result) => HelperFunctions.queryResult(result),
            skip: !id,
        },
    );
}

export const defaultFilterOptions = {
    content_verified: false,
    content_unverified: true,
    content_not_translated_yet: true,
    content_excluded: false,
};
