import { useParams } from 'react-router-dom';
import { idToUri } from '../../global/UriHelper';

export function useGetSelectedGroupId(asUri = false) {
    const { folder, folderId } = useParams();

    if (folderId === undefined || folder === 'list') {
        return '0';
    }

    if (asUri) {
        return idToUri(folderId, 'PublicationGroup');
    }

    return parseInt(folderId);
}
